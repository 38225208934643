import { resolveComponent as _resolveComponent, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-20fb8784"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "admin-dashboard" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tableau_viz = _resolveComponent("tableau-viz")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.state.dashboardUrl)
      ? (_openBlock(), _createBlock(_component_tableau_viz, {
          key: 0,
          id: "tableauViz",
          style: _normalizeStyle({ width: _ctx.dashboardWidth, height: _ctx.dashboardHeight }),
          token: _ctx.state.tableauToken,
          src: _ctx.state.dashboardUrl
        }, null, 8, ["style", "token", "src"]))
      : _createCommentVNode("", true)
  ]))
}