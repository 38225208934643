
import {
    defineComponent, onMounted, PropType, reactive,
} from 'vue';
import sidebarStore from '@/components/sidebar/store/SidebarStore';
import { getTranslation, getTitleCaseTranslation } from '@/services/TranslationService';
import useScreenSize from '@/composable/useScreenSize';
import UserService from '@/services/UserService';
import AuthStore from '@/store/AuthStore';
import CoreStore from '@/store/CoreStore';
import SiteModule from '@/domain/enums/SiteModule';

type State = {
    tableauToken: string | undefined;
    dashboardUrl: string | undefined;
};

export default defineComponent({
    name: 'dashboard-screen',
    props: {
        siteModule: {
            type: Object as PropType<SiteModule>,
            required: true,
        },
    },
    setup(props) {
        const state = reactive<State>({
            tableauToken: undefined,
            dashboardUrl: undefined,
        });

        const authStore = AuthStore.getInstance();
        const userService = new UserService();
        const coreStore = CoreStore.getInstance();

        const { screenWidth, screenHeight } = useScreenSize();
        const dashboardWidth = `${screenWidth.value - 250}px`;
        const dashboardHeight = `${screenHeight.value - 76}px`;

        onMounted(async () => {
            switch (props.siteModule) {
            case SiteModule.Admin:
                state.dashboardUrl = coreStore.adminSettingsStore.dashboardUrl;
                break;
            case SiteModule.PartnerEngagement:
                state.dashboardUrl = coreStore.pemSettingsStore.dashboardUrl;
                break;
            case SiteModule.FloorTrak:
            default:
                return;
            }

            // inject the tableau script into head once
            if (Array.from(document.head.querySelectorAll('script')).filter((x) => x.src.indexOf('tableau') > -1).length === 0) {
                const url = new URL(state.dashboardUrl);

                const tableauScript = document.createElement('script');
                tableauScript.setAttribute('type', 'module');
                tableauScript.setAttribute('src', `${url.origin}/javascripts/api/tableau.embedding.3.latest.min.js`);
                document.head.appendChild(tableauScript);
            }

            if (authStore.samAccountName) {
                const response = await userService.getTableauToken();
                if (response.success) {
                    state.tableauToken = response.data;
                }
            }
        });

        function toggleNav() {
            sidebarStore.getInstance().toggleSidebar();
        }

        return {
            getTranslation,
            getTitleCaseTranslation,
            toggleNav,
            dashboardWidth,
            dashboardHeight,
            state,
        };
    },
});
